<template>
	<div class="main-card-detail">
		<div class="title-box"><span class="m-title">基本信息</span></div>
		<div class="content-box information">
			<table id="basetable" cellspacing="0" cellpadding="0">
				<tr>
					<td>ICCID</td>
					<td>{{iccid}}</td>
					<td>套餐类型</td>
					<td><span>{{mealTypeStr}}</span></td>
					<td>套餐总流量</td>
					<td><span v-html="totalFlowId"></span></td>
				</tr>
				<tr>
					<td>MSISDN</td>
					<td>{{phone}}</td>
					<td>基础套餐</td>
					<td>{{cardPackage}}</td>
					<td>到期时间</td>
					<td class="data-table-input">
						<span>{{expireDate}}</span>
					</td>
				</tr>
				<tr>
					<td>运营商</td>
					<td >{{accountName2}}</td>
					<td>网卡状态</td>
					<td class="act2">
						<span>{{statusspan}}</span>
						<input name="status" id="status" style="width: 100%;" class="textbox" type="hidden" value='0'></input>
					</td>
					<td>已使用流量</td>
					<td><span>{{AlreadyFlowId}}</span></td>
				</tr>
				<tr>
					<td>卡类型</td>
					<td>
						<span>{{typespan}}</span>
						<input id="type" name="type" style="width: 100%;" class="textbox" type="hidden" value='0'></input>
					</td>
					<td>供应商</td>
					<td>{{accountName}}</td>
					<td>剩余流量</td>
					<td><span class="input-css" v-html="surplusFlowId"></span></td>
				</tr>
				<tr>
					<td>导入时间</td>
					<td>{{createDateTime}}</td>
					<td>生效时间</td>
					<td>{{takeEffectTime}}</td>
					<td></td>
					<td></td>
				</tr>
				<tr>
					<td>激活时间</td>
					<td><span>{{servCreateDate}}</span></td>
					<td>流量池名称</td>
					<td>{{flowPool}}</td>
					<td>当前套餐</td>
					<td><span v-html="packageTd"></span></td>
					<!-- {{packageTd}} -->
				</tr>

			</table>
		</div>
		<div class="mt20">
			<div class="title-box"><span class="m-title">常规操作</span></div>
			<div class="content-box operation ">
				<ul>
					<li>
						<a id="znzd" href="javascript:void(0)" style="text-decoration: none;" @click="znzd()" data-options="iconCls:'icon-save'">
							<img src="../../assets/2.png" alt="">
							<br />
							<span>智能诊断</span>
						</a>
					</li>
					<li>
						<a id="sendSms" v-if="sendSms" href="javascript:void(0)" style="text-decoration: none;" @click="dialogVisible = true" data-options="iconCls:'icon-save'">
							<img src="../../assets/2.png" alt="">
							<br />
							<span>短信</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<el-dialog title="发送" :visible.sync="dialogVisible" width="500px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="任务名称" prop="taskName">
					<el-input show-word-limit maxlength="32" v-model="ruleForm.taskName" placeholder="任务名称，最多32字"></el-input>
				</el-form-item>
				<el-form-item label="短信内容" prop="content">
					<el-input rows='3' show-word-limit maxlength="160" type='textarea' v-model="ruleForm.content" placeholder="短信内容，最多160字" @input="contentChanged"></el-input>
				</el-form-item>
				<el-form-item label="短信模板">
					<el-select v-model="ruleForm.smsTemplates" @change="templateChanged" style="width: 100%;">
						<el-option label="请选择" value=""></el-option>
						<el-option v-for="(item,index) in templateList" :key="item.id" :label="item.name" :value="item.content"></el-option>
					</el-select>
				</el-form-item>
				
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button class="themed-button" type="info" @click="dialogVisible = false">取 消</el-button>
				<el-button class="themed-button" type="primary" @click="sendMsg">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import {findCardInfoDetail,intelliDiagnose,submitOneSms} from '@/api/card.js'
	import {searchTemplateList} from '@/api/sms.js'
	import loading from '@/utils/Loading.js'
	export default {
		name:'cardDetail',
		data(){
			return{
				iccid:'',
				mealTypeStr:'',
				totalFlowId:'',
				phone:'',
				cardPackage:'',
				expireDate:'',
				accountName2:'',
				statusspan:'',
				AlreadyFlowId:'',
				typespan:'',
				accountName:'',
				surplusFlowId:'',
				createDateTime:'',
				takeEffectTime:'',
				servCreateDate:'',
				flowPool:'',
				packageTd:'',
				sendSms:false,
				__znzd_ing:false,
				dialogVisible:false,
				ruleForm:{},
				rules:{
					taskName: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
					content: [{
						required: true,
						message: '不能为空',
						trigger: 'blur'
					},],
				},
				templateList:[]
			}
		},
		created() {
			this.iccid = this.$route.query.iccidMark
			this.findCardInfo()
		},
		methods:{
			
			templateChanged(val){
				this.ruleForm.content = val
			},
			contentChanged(val){
				// this.ruleForm.content = val.replace(/[\W]/g,'')
				this.ruleForm.content = val.replace(/[\u4E00-\u9FA5]/g,'')
			},
			sendMsg(){
				this.$refs["ruleForm"].validate((validate) => {
					if (validate) {
						this.ruleForm.iccid = this.iccid
						submitOneSms(this.ruleForm).then(res=>{
							this.$message({
								type: 'success',
								message:'发送成功'
							});
							this.dialogVisible = false
						})
					}
				})
			},
			getSearchTemplateList(){
				searchTemplateList({}).then(res=>{
					this.templateList = res
				})
			},
			znzd(){
				if (this.__znzd_ing === true) {
				    this.$alert('正在诊断，请稍后...', '提示', {
				        confirmButtonText:'确定',
				    });
				    return
				}
				this.__znzd_ing = true
				intelliDiagnose({iccidMark:this.iccid}).then(res=>{
					this.__znzd_ing = false
					console.log(res);
					let msg = res.retMsg || res.system_result_message_key
					this.$alert(msg, '消息提示', {
					    confirmButtonText:'确定',
					});
				})
			},
			findCardInfo(){
				loading.show()
				findCardInfoDetail({iccidOrPhone:this.$route.query.iccidMark}).then(res=>{
					this.getSearchTemplateList()
					this.setCardInfo(res)
				})
			},
			setCardInfo(responseResult){
				
				    let buchongzhi = (typeof responseResult.buchongzhi) !=="undefined" && responseResult.buchongzhi===true;
				
				
				    let responseResultPage = responseResult;
				   
				  
				    if(null != responseResultPage.entity && "" != responseResultPage.entity && typeof( responseResultPage.entity) != "undefined"){
						this.mealTypeStr = responseResult.entity.mealTypeStr
				   
				      
				
				        if(responseResult.entity.type==1){
							this.typespan = '包月'
				            
				        }else if(responseResult.entity.type==2){
							this.typespan = '包年'
				            
				        }
						this.phone = responseResult.entity.phone
						this.cardPackage = responseResult.entity.setMealName
				        
				        
				        if(responseResult.entity.category == 7){
							this.accountName = "中国移动"
							this.accountName2 = "中国移动"
				            
				            
				        }else if(responseResult.entity.category == 4){
							this.accountName = "中国联通"
							this.accountName2 = "中国联通"
				           
				        }else if (responseResult.entity.category == 1){
							this.accountName = "中国电信"
							this.accountName2 = "中国电信"
				       
				        }
						this.servCreateDate = responseResult.entity.servCreateDate
				        this.flowPool = responseResult.entity.flowPool
				        
				        this.createDateTime = responseResult.entity.createDateTime.substr(0,4)+"-"+responseResult.entity.createDateTime.substr(4,2)+"-"+responseResult.entity.createDateTime.substr(6,2)+
				            " "+responseResult.entity.createDateTime.substr(8,2)+":"+responseResult.entity.createDateTime.substr(10,2)+":"+responseResult.entity.createDateTime.substr(12,2)
		
				        if(responseResult.entity.cuankaFlag==="1"){
							this.statusspan = "窜卡"
				            
				        }else if(responseResult.entity.status==1){
							this.statusspan = "待激活"
				            
				        }else if(responseResult.entity.status==2){
							this.statusspan = "正常"
				            
				        }else if(responseResult.entity.status==3){
							this.statusspan = "断网"
				            
				        }else if(responseResult.entity.status==4){
							this.statusspan = "停机"
				            
				        }else if(responseResult.entity.status==5){
							this.statusspan = "异常"
				            
				        }else if(responseResult.entity.status==6){
							this.statusspan = "强制停机"
				            
				        }else if(responseResult.entity.status==7){
							this.statusspan = "注销"
				            
				        }
						this.expireDate = responseResult.entity.expireDate
				        this.takeEffectTime = responseResult.entity.takeEffectTime
	
				        
				        var cumulationLeft = 0;//剩余流量
				        var cumulationTotal = 0;//总流量
				        var cumulationAlready=0;//已使用流量
				
		
				        if(responseResult.entity.category=='1' || responseResult.entity.category=='2' || responseResult.entity.category=='3' || responseResult.entity.category=='6' || responseResult.entity.category=='4'|| responseResult.entity.category=='5' || responseResult.entity.category=='7'|| responseResult.entity.category=='8'){
				            
				            var currSetmealFlow = 0;
				            var currSetmealName = "";
				            var list=responseResult.cardLiftList;
				            if(list){
				                var isLongDiKa = false;
				                var isMixedLeiJiMeal = false;
				                if(list&&list.length>0) {
				                    for(var i=0;i<list.length;i++) {
				                        if(isLongDiKa==false && (list[i].type==6||list[i].type==23)) {
				                            isLongDiKa = true;
				                            break;
				                        }
				
				                    }
				                    for(var i=0;i<list.length;i++) {
				                        if(isMixedLeiJiMeal==false && list[i].type==20 && list[i].secondType==2) {
				                            isMixedLeiJiMeal = true;
				                            break;
				                        }
				
				                    }
				                }
				                //除龙帝卡外（包月等），
				                //总流量只显示本周期内的，及叠加包。
				                if(!isLongDiKa && list && !isMixedLeiJiMeal) {
				                    var total = 0;
				                    var diejiabaoFlow = 0;
				                    var total_voice = 0;
				                    var diejiabaoFlow_voice = 0;
				                    var currDate = new Date();
				                    currDate.setHours(0);
				                    currDate.setMinutes(0);
				                    currDate.setSeconds(0);
				                    currDate.setMilliseconds(0);
				                    var ziranyue = false;
				                    var minExpireDate = null;// new Date(data.cardLiftList[0].expireDate.replace(/-/g,'/').substring(0,19));
				                    for(var i=0;i<list.length;i++) {
				                        if(list[i].type===9||list[i].type===10||list[i].type===14||list[i].type===20||list[i].type===23) {
				                            ziranyue = true;
				                            break;
				                        }
				                    }
				                    ziranyue = true;
				                    //找最小一个有效期，也就是本周期。
				                    for(var i=0;i<list.length;i++) {
				                        var clObj = list[i];
				                        var expireDate = new Date(clObj.expireDate.replace(/-/g,'/').substring(0,19));
				
				                        if(ziranyue) {
				                            if(expireDate.getTime()<currDate.getTime() ) {
				                                continue;
				                            }
				                        } else {
				                            if(expireDate.getTime()<=currDate.getTime() ) {
				                                continue;
				                            }
				                        }
				                        if(clObj.type==1) {
				                            diejiabaoFlow += clObj.flowSize;
				                        }
				                        if(clObj.type==101) {
				                            diejiabaoFlow_voice += clObj.voiceSize;
				                        }
				
				
				                        if(ziranyue) {
				                            if(minExpireDate==null && expireDate.getTime()>=currDate.getTime() && clObj.type!=1&& clObj.type!=101) {
				                                minExpireDate = expireDate;
				                            } else if (minExpireDate!=null){
				                                if(expireDate.getTime()>currDate.getTime() && expireDate.getTime()<minExpireDate.getTime() && clObj.type!=1&& clObj.type!=101) {
				                                    minExpireDate = expireDate;
				                                }
				                            }
				                        } else {
				                            if(minExpireDate==null && expireDate.getTime()>currDate.getTime() && clObj.type!=1 && clObj.type!=101) {
				                                minExpireDate = expireDate;
				                            } else if (minExpireDate!=null){
				                                if(expireDate.getTime()>currDate.getTime() && expireDate.getTime()<minExpireDate.getTime() && clObj.type!=1 && clObj.type!=101) {
				                                    minExpireDate = expireDate;
				                                }
				                            }
				                        }
				                    }
				                    if(minExpireDate==null) {
				                        for(var i=0;i<list.length;i++) {
				                            var clObj = list[i];
				                            var expireDate = new Date(clObj.expireDate.replace(/-/g,'/').substring(0,19));
				
				                            if(expireDate.getTime()<=currDate.getTime() || clObj.type==1|| clObj.type==101) {
				                                continue;
				                            }
				                            minExpireDate =new Date(clObj.expireDate.replace(/-/g,'/').substring(0,19) );
				                        }
				                    }
				                    //本周流量加起来。
				                    for(var i=0;i<list.length;i++) {
				                        var clObj = list[i];
				                        var expireDate = new Date(clObj.expireDate.replace(/-/g,'/').substring(0,19));
				                        if(ziranyue) {
				                            if(expireDate.getTime()<currDate.getTime() ) {
				                                continue;
				                            }
				                        } else {
				                            if(expireDate.getTime()<=currDate.getTime() ) {
				                                continue;
				                            }
				                        }
				
				                        if(minExpireDate&&expireDate.getTime() === minExpireDate.getTime() && clObj.type!=1&& clObj.type!=101 ) {
				                            total += parseFloat(clObj.flowSize);
				                            total_voice += parseFloat(clObj.voiceSize);
				                        }
				                    }
				                    responseResult.entity.flowSize=total+diejiabaoFlow;
				                    responseResult.entity.voiceSize=total_voice+diejiabaoFlow_voice;
				                }
				                //加上天卡的叠加包流量。如果不是天卡tianDjbFlowSize是0，加了也不影响。
				                responseResult.entity.flowSize = parseFloat(responseResult.entity.flowSize) + parseFloat(responseResult.tianDjbFlowSize);
								
								let packageTdstr =''
								list.forEach((item,i)=>{
									if(item.type != 1&&item.type != 101){
									    currSetmealFlow = item.flowSize;
									    currSetmealName = item.mealName;
									}
									packageTdstr += "<br>"+item.mealName+" "+"<span>"+item.expireDate.substring(0,10)+ " "+item.flowSize+"MB "+item.voiceSize+"分钟"+"</span><br>"
									
									
								})
								
				             
				                //次月生效套餐
								responseResult.nextCardLifeList.forEach((item,i)=>{
									packageTdstr += "<br>"+item.mealName+" "+"<span>(次月生效) "+item.flowSize+"MB"+"</span><br>"
								})
				              
				                //天卡叠加包。
								responseResult.tianDjbCardLifes.forEach((item,i)=>{
									packageTdstr += "<br>"+item.mealName+" "+"<span>"+item.expireDate.substring(0,10)+ " "+item.flowSize+"MB"+"</span><br>"
								})
								
						
				                this.packageTd = packageTdstr
				                
				            }
				
				            if(currSetmealFlow == 0    &&!(!isLongDiKa && list)  ){
								this.surplusFlowId = "<span class='MB'>∞ MB</span>"
				                this.totalFlowId = "∞ MB"
				                
				            }else{
								this.surplusFlowId = (responseResult.entity.flowSize - responseResult.entity.totalBytesCnt).toFixed(2) + "<span class='MB'>MB</span>"
				                this.totalFlowId = responseResult.entity.flowSize + "MB"
				         
				            }
				
				            var flowCnt = responseResult.entity.totalBytesCnt;
				            if(flowCnt && flowCnt!=""){
				                flowCnt = parseFloat(flowCnt).toFixed(2);
				            }
							this.AlreadyFlowId = flowCnt + "MB"
				            
				        }
				    }
				
				    if(responseResult.entity.toType == 'B'){
						this.sendSms = true
				    }else{
				        this.sendSms = false
				    }
				
				    
				
			}
			
		}
	}
</script>

<style>
	.main-card-detail .information table {
		width: 100%;
		/* border: 1px solid rgb(180, 224, 247); */
		border: 1px solid #e3e3e3;
	}

	.main-card-detail .information table td {
		/* border-right: 1px solid rgb(180, 224, 247);
	    border-bottom: 1px solid rgb(180, 224, 247); */
		border-right: 1px solid #e3e3e3;
		border-bottom: 1px solid #e3e3e3;
		padding: 10px;
	}

	.main-card-detail .information table tr>td:last-child {
		border-right: none;
	}

	.main-card-detail .information table tr:last-child td {
		border-bottom: none;
	}

	.main-card-detail .information table td:nth-of-type(2n+1) {
		/* background: #D1F0FF; */
		color: #999999;
		text-align: right;
	}

	.main-card-detail .information table td:nth-of-type(2n) {
		color: #333333;
		min-width: 100px;
	}
	.main-card-detail .operation ul,.main-card-detail .operation li{
	    list-style: none;
	}
	.main-card-detail .operation ul{
	    min-width: 1020px;
	    padding: 0;
	    color: #666666;
	    overflow: hidden;
	}
	.main-card-detail .operation ul li{
	    width: 100px;
	    margin: 0 5px;
	    text-align: center;
	    float: left;
	}
	.main-card-detail .operation ul li img{
	    width: 60px;
	    padding: 8px 0 0;
	}
	.main-card-detail .operation ul p{
	    /*width: 50px;
	    height: 30px;*/
	    margin: 0;
	    margin-bottom: 8px;
	    color: #666666;
	}
</style>
