import request from './request.js'
export function cardList(params) {
    return request({
        url: 'card/enterpriseList.do?',
        method: 'post',
        data: params
    })
}
export function findCardInfoDetail(params) {
    return request({
        url: 'card/findCardInfo.do?',
        method: 'post',
        data: params
    })
}
export function exportCardInfo(params){
  return request({
    url: 'card/exportEnterpriseExcel.do?',
    method: 'post',
    params:params,
	
  })
}
export function intelliDiagnose(params) {
    return request({
        url: 'card/intelliDiagnose.do?',
        method: 'post',
        data: params
    })
}
export function submitOneSms(params) {
    return request({
        url: 'batchSms/submitOneSms.do?',
        method: 'post',
        data: params
    })
}
