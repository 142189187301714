import request from './request.js'
export function batchSmsList(params) {
    return request({
        url: 'batchSms/list.do?',
        method: 'post',
        data: params
    })
}
export function templateList(params) {
    return request({
        url: 'sms/template/list.do?',
        method: 'post',
        data: params
    })
}
export function searchTemplateList(params) {
    return request({
        url: 'sms/template/listAll.do?',
        method: 'post',
        data: params
    })
}

export function saveTemplate(params) {
    return request({
        url: 'sms/template/save.do?',
        method: 'post',
        data: params
    })
}
export function deleteTemplate(params) {
    return request({
        url: 'sms/template/deleteById.do?',
        method: 'post',
        data: params
    })
}
export function smslist(params) {
    return request({
        url: 'sms/list.do?',
        method: 'post',
        data: params
    })
}
export function submitBatchSms(params) {
    return request({
        url: 'batchSms/submitBatchSms.do?',
        method: 'post',
        data: params,
		timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}


export function exportInput(params) {
    return request({
        url: 'batchSms/exportInput.do?',
        method: 'post',
        data: params,
		responseType: 'blob'
    })
}
export function exportOutput(params) {
    return request({
        url: 'batchSms/exportResult.do?',
        method: 'get',
        data: params,
		responseType: 'blob'
    })
}
